import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"

const StyledContainer = styled.div`
  padding: 2rem;
  width: 100%;
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 0 auto;
    max-width: 96rem;
  `}
`

const StyledArticle = styled.article`
  background: ${(props) => props.theme.colors.gray_light};
  padding: 4rem 2rem;

  .inner {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    padding: 4rem 2rem;
    max-width: 96rem;
    margin: 0 auto;
  }

  h1 {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1.4;
  }

  h2 {
    margin-top: 1.5em;
    font-size: 2.1rem;
    line-height: 1.4;
  }

  h3 {
    margin-top: 1.5em;
    font-size: 1.8rem;
    line-height: 1.3;
  }

  a {
    color: ${(props) => props.theme.colors.bilkablue};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 4rem 0;

    .inner {
      padding: 4rem;
    }

    h1 {
      margin: 0;
      font-size: 3.2rem;
      line-height: 1.4;
      max-width: 80%;
    }

     h2 {
      font-size: 2.6rem;
      max-width: 80%;
    }

    h3 {
      font-size: 2.2rem;
      max-width: 80%;
    }

    p, li {
      max-width: 80%;
    }
  `}
`

function Cookies({ data: { site, prismic }, ...props }) {
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = "/cookies/"
  const containerRef = useRef(null)

  const {
    site_settings: {
      default_meta_title: meta_title,
      default_meta_description: meta_description,
      default_meta_image: meta_images,
    },
    mainPage: { body },
  } = prismic

  const onPageNav = body
    .map((s) => {
      if (s.hasOwnProperty("primary") && s.primary.anchor_title) {
        return {
          anchor: s.primary.anchor_title,
        }
      }
      return
    })
    .filter(Boolean)

  useEffect(() => {
    const script = document.createElement("script")

    if (typeof window !== undefined && containerRef.current !== null) {
      script.id = "CookiePolicy"
      script.src = "https://policy.app.cookieinformation.com/cid.js"
      script.defer = true
      script["data-culture"] = "DA"
      containerRef.current.appendChild(script)
    }

    return () => {
      containerRef.current.removeChild(script)
    }
  }, [])

  return (
    <>
      <SEO
        lang="da-dk"
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
        defaultMeta={defaultMeta}
      />
      <Layout site_settings={prismic.site_settings} pageNav={onPageNav}>
        <StyledArticle>
          <div className="inner" ref={containerRef} />
        </StyledArticle>
      </Layout>
    </>
  )
}

export default Cookies

Cookies.defaultProps = {}
Cookies.propTypes = {
  children: PropTypes.node.isRequired,
}

export const query = graphql`
  query CookiePage($isProduction: Boolean!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: "da-dk", uid: "site_settings") {
        copyright_information
        ...PrismicSiteSettingsMetaFragment
        _meta @include(if: $isProduction) {
          uid
        }
      }
      mainPage: page(lang: "da-dk", uid: "kitchenaid") {
        _meta {
          type
          lang
          uid
        }
        body {
          ... on PRISMIC_PageBodyParagraph___image {
            primary {
              use_anchor
              anchor_title
            }
          }
          ... on PRISMIC_PageBodyFeature_repeater {
            primary {
              use_anchor
              anchor_title
            }
          }
          ... on PRISMIC_PageBodyProduct_repeater {
            primary {
              use_anchor
              anchor_title
            }
          }
        }
      }
    }
  }
`
